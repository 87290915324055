<template>
  <div class="container flex flex-col gap-4 border-2 border-tp-yellow p-4 rounded-xl w-full mx-auto" :class="{'container-open': isOpen, 'container': !isOpen}">
    <div class="flex gap-4 justify-between items-center cursor-pointer" @click="toggle">
      <p class="text-xl sm:text-2xl font-medium">{{ question }}</p>
      <div class="transform transition-transform duration-300" :class="{ 'rotate-45': isOpen, 'rotate-0': !isOpen }">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 4v16m8-8H4"
          />
        </svg>
      </div>
    </div>
    <div class="answer" :class="{'answer-open': isOpen, 'answer': !isOpen}">
      <p v-html="answer" class="text-lg"></p>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue';

defineProps({
  question: String,
  answer: String,
});

const isOpen = ref(false);

const toggle = () => {
  isOpen.value = !isOpen.value;
};
</script>

<style scoped>
.container {
  max-height: 90px;
  transition: max-height 0.5s ease-in-out;
}

@media (min-width: 530px) {
  .container {
    max-height: 70px;
  }
}

.container-open {
  max-height: 500px; /* Ajusta esto a tu contenido máximo */
}

.answer {
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s, opacity .5s ease-in-out;
}

.answer-open {
    visibility: visible;
    opacity: 1;
    transition-delay: .2s;
}

</style>
