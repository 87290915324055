<template>
  <!-- <PreLoader /> -->
  <HeroSection />
  <!-- <TextParallaxSection /> -->
  <FeaturesSection />
  <DJSection />
  <ContactUs />
</template>

<script setup>
import { watch } from 'vue';
import ContactUs from '@/components/ContactUs.vue'
import HeroSection from '@/components/HeroSection.vue'
// import TextParallaxSection from '@/components/TextParallaxSection.vue'
import DJSection from '@/components/DJSection.vue'
import FeaturesSection from '@/components/FeaturesSection.vue'
import { eventBus } from '@/assets/js/eventBus';
import { useAnimationObserver } from '@/composables/useAnimationObserver';

useAnimationObserver();

function scrollToSection(targetId) {
  const targetElement = document.querySelector(targetId);
  console.log("Scrolling to:", targetElement);

  const offset = 150;

  if (targetElement) {
    const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
}

watch(
  () => eventBus.targetId,
  (newVal) => {
    console.log("Target ID changed:", newVal);  // Debugging line
    if (newVal) {
      scrollToSection(newVal);
      // Clear the targetId to prevent repeated scrolling
      eventBus.targetId = null;
    }
  }
);
</script>
