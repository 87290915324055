<template>
  <div class="flex flex-col items-center gap-10 pt-28 xs:pt-40 pb-20 px-8 lg:px-28 2xl:px-64 min-h-screen animated-block-y">
    <p class="font-bold text-4xl sm:text-5xl xl:text-6xl text-center">Términos y Condiciones</p>
    <div class="flex flex-col gap-6 text-xl">
      <p>
        Al acceder a nuestra aplicación, estás aceptando estos Términos y Condiciones. Estos términos son aplicables a todos los usuarios de la aplicación, sin excepción. Por favor, léelos detenidamente ya que al continuar utilizando nuestro servicio, estás de acuerdo con los términos detallados a continuación.
      </p>

      <div class="space-y-1">
        <h3 class="text-xl font-semibold">Uso del Servicio</h3>
        <p>
          La aplicación está destinada a proporcionar una plataforma para que los usuarios interactúen con nuestro contenido de manera segura y eficiente. Cualquier uso indebido del servicio, incluyendo pero no limitado a, la recolección de datos no autorizados, la alteración del código de la aplicación o cualquier otro comportamiento malicioso, está estrictamente prohibido.
        </p>
      </div>

      <div class="space-y-1">
        <h3 class="text-xl font-semibold">Modificaciones de los Términos</h3>
        <p>
          Nos reservamos el derecho a modificar estos Términos en cualquier momento sin previo aviso. Es responsabilidad del usuario revisar periódicamente esta sección para estar informado de cualquier cambio. El uso continuado de la aplicación tras la publicación de cambios en los Términos constituirá la aceptación de dichos cambios.
        </p>
      </div>

      <div class="space-y-1">
        <h3 class="text-xl font-semibold">Propiedad Intelectual</h3>
        <p>
          Todo el contenido, software, gráficos y materiales presentes en esta aplicación son propiedad de nuestra empresa o de terceros que nos han concedido una licencia. El uso no autorizado de estos materiales puede infringir leyes de propiedad intelectual y será sancionado conforme a la legislación vigente.
        </p>
      </div>

      <div class="space-y-1">
        <h3 class="text-xl font-semibold">Política de Privacidad</h3>
        <p>
          Tu privacidad es importante para nosotros. La recopilación y uso de la información personal está detallada en nuestra <a href="/privacy" class="underline">Política de Privacidad</a>. Al utilizar esta aplicación, consientes la recopilación y uso de información de acuerdo con dicha política.
        </p>
      </div>

      <div class="space-y-1">
        <h3 class="text-xl font-semibold">Limitación de Responsabilidad</h3>
        <p>
          No seremos responsables por cualquier daño directo, indirecto o incidental resultante del uso de la aplicación. El usuario asume toda la responsabilidad y el riesgo por el uso de la aplicación y los servicios proporcionados.
        </p>
      </div>

      <div class="space-y-1">
        <h3 class="text-xl font-semibold">Terminación del Servicio</h3>
        <p>
          Nos reservamos el derecho de suspender o terminar tu acceso a la aplicación sin previo aviso si consideramos que has incumplido estos Términos o cualquier ley aplicable. En caso de terminación, seguirán vigentes las disposiciones relacionadas con la propiedad intelectual, las limitaciones de responsabilidad y otras condiciones relevantes.
        </p>
      </div>

      <p>
        Si tienes alguna pregunta o necesitas más información sobre nuestros Términos y Condiciones, puedes contactarnos a través de nuestro equipo de soporte.
      </p>
    </div>
  </div>
</template>

<script setup>
import { useAnimationObserver } from '@/composables/useAnimationObserver';

useAnimationObserver();
</script>