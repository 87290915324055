<template>
    <div class="infinite-scroll animated-block">
        <ul>
            <li>
                <span class="font-bold">¡Atención!</span><span class="font-medium"> Zona para discotecas y DJs<span class="spin">&#x1F4BF;</span></span>
            </li>
            <li>
                <span class="font-bold">¡Atención!</span><span class="font-medium"> Zona para discotecas y DJs<span class="spin">&#x1F4BF;</span></span>
            </li>
            <li>
                <span class="font-bold">¡Atención!</span><span class="font-medium"> Zona para discotecas y DJs<span class="spin">&#x1F4BF;</span></span>
            </li>
            <li>
                <span class="font-bold">¡Atención!</span><span class="font-medium"> Zona para discotecas y DJs<span class="spin">&#x1F4BF;</span></span>
            </li>
            <li>
                <span class="font-bold">¡Atención!</span><span class="font-medium"> Zona para discotecas y DJs<span class="spin">&#x1F4BF;</span></span>
            </li>
            <li>
                <span class="font-bold">¡Atención!</span><span class="font-medium"> Zona para discotecas y DJs<span class="spin">&#x1F4BF;</span></span>
            </li>
            <li>
                <span class="font-bold">¡Atención!</span><span class="font-medium"> Zona para discotecas y DJs<span class="spin">&#x1F4BF;</span></span>
            </li>
            <li>
                <span class="font-bold">¡Atención!</span><span class="font-medium"> Zona para discotecas y DJs<span class="spin">&#x1F4BF;</span></span>
            </li>
        </ul>

        <ul aria-hidden="true">
            <li>
                <span class="font-bold">¡Atención!</span><span class="font-medium"> Zona para discotecas y DJs<span class="spin">&#x1F4BF;</span></span>
            </li>
            <li>
                <span class="font-bold">¡Atención!</span><span class="font-medium"> Zona para discotecas y DJs<span class="spin">&#x1F4BF;</span></span>
            </li>
            <li>
                <span class="font-bold">¡Atención!</span><span class="font-medium"> Zona para discotecas y DJs<span class="spin">&#x1F4BF;</span></span>
            </li>
            <li>
                <span class="font-bold">¡Atención!</span><span class="font-medium"> Zona para discotecas y DJs<span class="spin">&#x1F4BF;</span></span>
            </li>
            <li>
                <span class="font-bold">¡Atención!</span><span class="font-medium"> Zona para discotecas y DJs<span class="spin">&#x1F4BF;</span></span>
            </li>
            <li>
                <span class="font-bold">¡Atención!</span><span class="font-medium"> Zona para discotecas y DJs<span class="spin">&#x1F4BF;</span></span>
            </li>
            <li>
                <span class="font-bold">¡Atención!</span><span class="font-medium"> Zona para discotecas y DJs<span class="spin">&#x1F4BF;</span></span>
            </li>
            <li>
                <span class="font-bold">¡Atención!</span><span class="font-medium"> Zona para discotecas y DJs<span class="spin">&#x1F4BF;</span></span>
            </li>
        </ul>
    </div>
</template>

<style scoped>

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  display: inline-block; 
  width: 20px; 
  height: 20px; 
  animation: spin 2s linear infinite; 
  transform-origin: center; 
}

@media (max-width: 520px) {
    .spin {
        line-height: 20px;
    }
}

.infinite-scroll {
  display: flex;
  width: 100%;
  font-size: 15px;
  padding-block: 8px;
  border-block: 1px solid;
  overflow: hidden;
  user-select: none;
  --gap: 20px;
  gap: var(--gap);
}

.infinite-scroll ul {
  list-style: none;
  flex-shrink: 0;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--gap);

  animation: scroll 30s linear infinite;
}

.infinite-scroll:hover ul {
  animation-play-state: paused;
}

@keyframes scroll {
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

</style>