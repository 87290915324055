<template>
    <div class="flex flex-col items-center gap-4 pt-10 pb-20 px-8 lg:px-28 animated-block-y">
        <div class="flex justify-center relative mt-6 sm:mt-10 md:mt-14 lg:mt-20 xl:mt-28 2xl:mt-48">
            <div class="gradient-bg"></div>
            <div class="absolute  sm:-top-5 md:-top-10 xl:-top-20 2xl:-top-36">
                <div class="dj-animated-text-container">
                    <div class="dj-animated-text-header">
                        <div class="header concat">
                            <h1>Gana <span class="italic normal-case">dinero</span></h1>
                            <div class="header-wrapper"></div>
                        </div>
                        <div class="header concat">
                            <h1>monetizando</h1>
                            <div class="header-wrapper"></div>
                        </div>
                        <div class="header concat">
                            <h1>tus <span class="italic normal-case">sesiones</span></h1>
                            <div class="header-wrapper"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dj-table-container z-10">
                <img class="hidden sm:block max-w-xl md:max-w-2xl lg:max-w-4xl" :src="djTable" alt="">
                <img class="sm:hidden max-w-xs xs:max-w-sm transform -translate-y-7" :src="djTableXs" alt="">
            </div>
        </div>
    </div>
</template>

<script setup>
import djTable from '@/assets/images/dj-table.png'
import djTableXs from '@/assets/images/dj-table-xs.png'
import { onMounted, ref } from 'vue';
import { gsap } from 'gsap';

const isVisible = ref(false);

onMounted(() => {
  const element = document.querySelector('.animated-block-y');

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        isVisible.value = true;
        observer.unobserve(entry.target);
        animate(); 
      }
    });
  });

  observer.observe(element);

  const animate = () => {
    const tl = gsap.timeline();

    tl.from(".dj-table-container", {
      duration: 1.5,
      opacity: 0,
      y: 50,
      ease: "power3.out",
    });

    tl.to(".dj-animated-text-header .header > h1", {
      duration: 2,
      opacity: 1,
      top: 0,
      ease: "power3.inOut",
      stagger: {
        amount: 0.3
      },
    }, "-=1.5");

    tl.from(".gradient-bg", {
      duration: 5,
      opacity: 0,
      ease: "power3.out",
    }, "-=1");
  };
});
</script>

<style scoped>
.gradient-bg {
    background: radial-gradient(50% 49.99999999999999% at 50% 49.99999999999999%, #ffffff 0%, rgba(156, 156, 251, 0.8) 24.285714285714285%, rgba(113, 114, 249, 0.4) 45%, rgba(36, 36, 79, 0.4) 74.64285714285714%, rgba(0, 18, 41, 0) 100%);
    height: 700px;
    width: 800px;
    position: absolute;
    transform: translateY(-13%);
    overflow: hidden;
}

@media (max-width: 1024px) {
    .gradient-bg {
        height: 500px;
        width: 600px;
        transform: translateY(-10%);
    }
}

@media (max-width: 768px) {
    .gradient-bg {
        height: 400px;
        width: 500px;
        transform: translateY(-10%);
    }
}

@media (max-width: 640px) {
    .gradient-bg {
        height: 350px;
        width: 350px;
        transform: translateY(-10%);
    }
}

.dj-animated-text {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.dj-animated-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dj-animated-text-container .header {
  position: relative;
  width: max-content;
  margin: 0 auto;
}

.concat {
    display: flex;
}

.header h1 {
    position: relative;
    top: 125px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    font-size: 6vw;
    line-height: 90%;
    color: #F9F871;
    overflow: hidden; 
    padding-right: 10px;
    opacity: 0;
}

@media (max-width: 520px) {
  .header h1 {
    font-size: 4vh;
  }
}

.header-wrapper:after {
    content: "";
    position: absolute;
    width: 110%;
    height: 120px;
    top: 140px;
    left: -20px;
    margin: 0 auto;
}

</style>
    