<template>
    <div id="form" class="w-full py-20">
        <div class="m-auto whitespace-nowrap overflow-hidden pt-5 animated-block">
            <span class="font-bold text-tp-yellow text-4xl md:text-6xl sm:text-8xl uppercase inline-block marquee">
                contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos /
            </span>
        </div>

        <div class="flex flex-col gap-10 px-8 lg:px-28 pt-10">
            <div class="hidden md:block animated-block">
                <p v-if="!isDiscoMode" class="font-semibold text-2xl sm:text-5xl w-full xl:w-2/3 transition-opacity duration-500 ease-in-out" :class="isDiscoMode ? 'opacity-0' : 'opacity-100'">
                    Impulsa tu carrera como DJ <br>
                    dando la oportunidad a tu<br>
                    público de elegir la música.
                </p>
                <p v-if="isDiscoMode" class="font-semibold text-2xl sm:text-5xl w-full xl:w-2/3 transition-opacity duration-500 ease-in-out" :class="isDiscoMode ? 'opacity-100' : 'opacity-0'">
                    Ofrece a tus clientes el poder<br>
                    de decidir la música y crea noches<br>
                    inolvidables en tu discoteca.
                </p>
            </div>

            <div class="md:hidden animated-block">
                <p v-if="!isDiscoMode" key="dj-mode" class="font-semibold text-2xl sm:text-5xl w-full xl:w-2/3 transition-opacity duration-500 ease-in-out opacity-100" :class="isDiscoMode ? 'opacity-0' : 'opacity-100'">
                    Impulsa tu carrera como DJ
                    dando la oportunidad a tu
                    público de elegir la música.
                </p>
                <p v-if="isDiscoMode" key="disco-mode" class="font-semibold text-2xl sm:text-5xl w-full xl:w-2/3 transition-opacity duration-500 ease-in-out opacity-0" :class="isDiscoMode ? 'opacity-100' : 'opacity-0'">
                    Ofrece a tus clientes el poder
                    de decidir la música y crea noches
                    inolvidables en tu discoteca.
                </p>
            </div>

            <div class="flex py-4 animated-block">
                <label class="flex gap-4 items-center cursor-pointer">
                    <span class="text-xl font-medium" :class="!isDiscoMode ? 'text-tp-yellow' : ''">DJ</span>
                    <div class="relative">
                        <input type="checkbox" v-model="isDiscoMode" class="sr-only">
                        <div class="block bg-tp-yellow w-14 h-8 rounded-full"></div>
                        <div
                            class="dot absolute left-1 top-1 bg-tp-black w-6 h-6 rounded-full transition-transform"
                            :class="isDiscoMode ? 'translate-x-full' : 'translate-x-0'"
                        ></div>
                    </div>
                    <span class="text-xl font-medium" :class="isDiscoMode ? 'text-tp-yellow' : ''">Discoteca</span>
                </label>
            </div>

            <div class="flex flex-col gap-6 sm:gap-10 text-xl text-tp-yellow w-full xl:w-1/2">
                <div class="animated-block">
                    <div v-if="!isDiscoMode" key="dj-inputs" class="flex flex-col sm:flex-row gap-6 transition-opacity duration-500 ease-in-out opacity-100" :class="isDiscoMode ? 'opacity-0' : 'opacity-100'">
                        <input v-model="form.name" class="bg-transparent outline-none border-b border-tp-yellow p-2 w-full" type="text" placeholder="Nombre y apellidos">
                        <input v-model="form.email" class="bg-transparent outline-none border-b border-tp-yellow p-2 w-full" type="text" placeholder="Email">
                    </div>
                    <div v-if="isDiscoMode" key="disco-inputs" class="flex flex-col sm:flex-row gap-6 transition-opacity duration-500 ease-in-out opacity-0" :class="isDiscoMode ? 'opacity-100' : 'opacity-0'">
                        <input v-model="form.name" class="bg-transparent outline-none border-b border-tp-yellow p-2 w-full" type="text" placeholder="Nombre de la discoteca">
                        <input v-model="form.email" class="bg-transparent outline-none border-b border-tp-yellow p-2 w-full" type="text" placeholder="Email">
                    </div>
                </div>

                <div v-if="!isDiscoMode" key="dj-inputs" class="flex flex-col sm:flex-row gap-6 transition-opacity duration-500 ease-in-out opacity-100 animated-block" :class="isDiscoMode ? 'opacity-0' : 'opacity-100'">
                        <input v-model="form.location" class="bg-transparent outline-none border-b border-tp-yellow p-2 w-full" type="text" placeholder="Localización">
                        <input v-model="form.instagram" class="bg-transparent outline-none border-b border-tp-yellow p-2 w-full" type="text" placeholder="@ en Instagram">
                    </div>

                <textarea v-model="form.message" class="w-full bg-transparent outline-none border-b border-tp-yellow p-2 animated-block" name="" id="message" placeholder="Mensaje" rows="5"></textarea>

                <button @click="sendEmail" class="flex items-center gap-2 sm:gap-6 w-full cursor-pointer text-tp-yellow text-3xl sm:text-6xl font-semibold uppercase no-underline animated-block" href="">Enviar
                    <lord-icon
                        src="https://cdn.lordicon.com/whtfgdfm.json"
                        trigger="hover"
                        colors="primary:#F9F871" class="w-10 h-10 sm:w-24 sm:h-24">
                    </lord-icon>
                </button>
                <div v-if="submitStatus === 'success'" class="text-green-500">¡Correo enviado con éxito!</div>
                <div v-if="submitStatus === 'error'" class="text-red-500">Error al enviar el correo.</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';

const isDiscoMode = ref(false);

const form = ref({
  name: '',
  location: '',
  email: '',
  instagram: '',
  message: '',
});

const submitStatus = ref('');

const sendEmail = async () => {
  try {
    const url = `https://api.dev.tuparty.club/contact`;
    const formData = new FormData();

    for (const key in form.value) {
      formData.append(key, form.value[key]);
    }

    const method = 'post';
    
    
    const response = await axios.request({
        method,
        url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    });
    
    if (response.status === 200) {
      submitStatus.value = 'success';
      resetForm();
    } else {
      throw new Error('Error al enviar el correo.');
    }
  } catch (error) {
    console.error('Error:', error);
    submitStatus.value = 'error';
  }
};

const resetForm = () => {
  form.value.name = '';
  form.value.location = '';
  form.value.email = '';
  form.value.instagram = '';
  form.value.message = '';
  submitStatus.value = '';
};
</script>

<style scoped>
.marquee {
    padding-left: -10%;
    animation: marquee-animation 80s linear infinite;
}

@keyframes marquee-animation {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}

.dot {
    transition: transform 0.3s ease;
}
.translate-x-full {
    transform: translateX(100%);
}
.translate-x-0 {
    transform: translateX(0);
}
</style>