<template>
    <div class="flex flex-col items-center gap-10 pt-28 xs:pt-40 pb-20 px-8 lg:px-28 min-h-screen animated-block-y">
        <p class="font-bold text-4xl sm:text-5xl xl:text-6xl text-center">Preguntas Frecuentes</p>
        <div class="flex flex-col gap-4 w-full">
            <QuestionComponent :question="question1" :answer="answer1" />
            <QuestionComponent :question="question2" :answer="answer2" />
            <QuestionComponent :question="question3" :answer="answer3" />
            <QuestionComponent :question="question4" :answer="answer4" />
        </div>
    </div>
</template>

<script setup>
import QuestionComponent from '@/components/FAQ/QuestionComponent.vue'
import { useAnimationObserver } from '@/composables/useAnimationObserver';

useAnimationObserver();

const question1 = '¿Qué pasa si no suena mi canción?'
const answer1 = 'Si tu canción no suena, se te devolverá el importe completo en un plazo máximo de 10 días.<br> La devolución por nuestra parte se procesa al instante pero hay ciertos bancos que pueden tardar hasta 10 días en reflejar el importe en tu cuenta.'

const question2 = '¿La gente elige todas las canciones?'
const answer2 = 'No, el DJ elige cada cuánto hacer una votación en función del ambiente y la energía de la fiesta.'

const question3 = '¿El DJ puede rechazar alguna canción?'
const answer3 = 'Sí, el DJ tiene la opción de rechazar una canción si no la considera adecuada para la fiesta.'

const question4 = '¿Hace falta descargarse una aplicación?'
const answer4 = 'No, ¡solo escaneando un QR estás dentro!'
</script>