<template>
    <div class="flex flex-col items-center gap-12 px-8 lg:px-28 sm:my-16">
        <p class="font-semibold text-center text-4xl sm:text-5xl xl:text-6xl animated-block-y">Beneficios de usar TuParty</p>
        <div class="grid gap-4 grid-cols-1 sm:grid-cols-2 max-w-3xl">
            <div class="flex flex-col gap-4">
                <DJBenefitCard :title="title1" :description="description1" :image="image1" />
                <DJBenefitCard :title="title3" :description="description3" :image="image3" />
            </div>
            <div class="flex flex-col gap-4 sm:pt-16">
                <DJBenefitCard :title="title2" :description="description2" :image="image2" />
                <DJBenefitCard :title="title4" :description="description4" :image="image4" />
            </div>
            
        </div>
    </div>
</template>

<script setup>
import DJBenefitCard from '@/components/DJ/DJBenefitCard.vue'

const title1 = 'Monetiza tus sesiones'
const description1 = 'Gana un porcentaje de lo que se genera en cada sesión y aumenta tus ingresos.'
const image1 = '<lord-icon src="https://cdn.lordicon.com/gjjvytyq.json" trigger="loop" delay="1500" state="in-wallet" colors="primary:#F9F871" style="width:80px;height:80px;filter: drop-shadow(0 0 2px #0D0D0D);"></lord-icon>'

const title2 = 'Conecta con tu audiencia'
const description2 = 'Involucra a los asistentes de la fiesta permitiéndoles elegir la música que les gusta.'
const image2 = '<lord-icon src="https://cdn.lordicon.com/jcepibgt.json" trigger="loop" delay="1500" state="in-celebration" colors="primary:#F9F871" style="width:80px;height:80px;filter: drop-shadow(0 0 2px #0D0D0D);"></lord-icon>'

const title3 = 'Feedback en tiempo real'
const description3 = 'Observa las preferencias del público y adapta tu playlist al instante.'
const image3 = '<lord-icon src="https://cdn.lordicon.com/hbzwsetw.json" trigger="loop" delay="1500" state="in-thumbs" colors="primary:#F9F871" style="width:80px;height:80px;filter: drop-shadow(0 0 2px #0D0D0D);"></lord-icon>'

const title4 = 'Fácil de usar'
const description4 = 'Gestión intuitiva de las votaciones, todo desde un único lugar.'
const image4 = '<lord-icon src="https://cdn.lordicon.com/wkvacbiw.json" trigger="loop" delay="1500" state="in-build" colors="primary:#F9F871" style="width:80px;height:80px;filter: drop-shadow(0 0 2px #0D0D0D);"></lord-icon>'

</script>

