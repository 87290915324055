<template>
    <div id="user" class="mb-24 mt-16">
        <div class="flex flex-col gap-20">
            <div class="flex flex-col gap-6 px-8 lg:px-28">
                <p class="font-bold text-4xl sm:text-5xl xl:text-6xl animated-block">Solo 3 pasos</p>
                <div class="grid gap-4 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
                    <StepCard class="step-card animated-block" :title="stepTitle1" :icon="qrIcon" />
                    <StepCard class="step-card animated-block" :title="stepTitle2" :icon="rankingIcon" />
                    <StepCard class="step-card animated-block md:col-span-2 md:justify-self-center xl:col-span-1 xl: justify-self-start" :title="stepTitle3" :icon="musicIcon" />
                </div>
                <!-- <ResponsiveStepCards :title1="stepTitle1" :title2="stepTitle2" :title3="stepTitle3" :description1="stepDescription1" :description2="stepDescription2" :description3="stepDescription3" :icon1="qrIcon" :icon2="rankingIcon" :icon3="musicIcon"/> -->
            </div>
            <div class="flex flex-col gap-10">
                <div class="flex flex-col gap-6 px-8 lg:px-28">
                    <p class="font-bold text-4xl sm:text-5xl xl:text-6xl animated-block">2 tipos de votaciones</p>
                    <div class="flex flex-col lg:flex-row gap-10">
                        <FeatureComponent class="feature-component animated-block" :title="featureTitle1" :description="featureDescription1" :image="mockup2" />
                        <FeatureComponent class="feature-component animated-block" :title="featureTitle2" :description="featureDescription2" :image="mockup" />
                    </div>
                </div>
                <UserBenefitsSection class="animated-block" />
            </div>
        </div>
    </div>
</template>

<script setup>
import UserBenefitsSection from '@/components/Features/UserBenefitsSection.vue'
import FeatureComponent from '@/components/Features/FeatureComponent.vue'
// import ResponsiveStepCards from '@/components/Features/ResponsiveStepCards.vue'
import StepCard from '@/components/Features/StepCard.vue'
import mockup from '@/assets/images/ranking-mockup-gif.gif'
import mockup2 from '@/assets/images/ranking-mockup-gif2.gif'
import qrIcon from '@/assets/images/qr-code.png'
import rankingIcon from '@/assets/images/ranking-icon.png'
import musicIcon from '@/assets/images/musical-note.png'

const featureTitle1 = "4-hits"	
const featureDescription1 = "Elige entre las selecciones del DJ"

const featureTitle2 = "Ranking"
const featureDescription2 = "Compite para que TU canción acabe en primera posición"

const stepTitle1 = "Escanea el QR"

const stepTitle2 = "Vota por tu canción favorita"

const stepTitle3 = "¡Disfruta de la canción más votada!"

</script>
