<template>
    <div class="flex flex-col items-center gap-12 px-8 lg:px-28 mt-24">
        <p class="font-semibold text-center text-4xl sm:text-5xl xl:text-6xl animated-block-y">¿Cómo funciona?</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 justify-center items-center gap-10 2xl:gap-20">
            <DJStep :number="1" :text=text1 />
            <DJStep :number="2" :text=text2 />
            <DJStep :number="3" :text=text3 />
            <DJStep :number="4" :text=text4 />
        </div>
    </div>
</template>

<script setup>
import DJStep from '@/components/DJ/DJStep.vue'
const text1 = "Crea tu sesión<br /> TuParty"
const text2 = "Elige el tipo <br /> de votación"
const text3 = "Lanza la <br /> cuenta atrás"
const text4 = "Reproduce<br />la canción<br />ganadora"
</script>