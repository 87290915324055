import { onMounted } from "vue";

export function useAnimationObserver() {
  onMounted(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(".animated-block");
    hiddenElements.forEach((element) => observer.observe(element));

    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show-y");
        }
      });
    });

    const hiddenElementsY = document.querySelectorAll(".animated-block-y");
    hiddenElementsY.forEach((element) => observer2.observe(element));
  });
}
